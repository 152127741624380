(function (el) {
  if (!el) return;

  new Vue({
    el: el,
    data: {
      q: '',
      query: '',
      results: [],
    },
    mounted() {
      if (el.dataset.query) {
        this.q = el.dataset.query;
        this.query = el.dataset.query;
        this.search();
      }
    },
    methods: {
      search: function () {
        if (!this.q) {
          this.results = [];
          return;
        }
        axios.get('/search/blog/?q=' + this.q).then((response) => {
          this.results = response.data;
        });
      },
      doSearch: function () {
        if (!this.q) {
          return;
        }
        const url = new URL(location);
        url.searchParams.set('q', this.q);
        location = url;
      },
    },
  });
})(document.getElementById('blogSearchPage'));
