$(function () {
  if (!$('#mapa-espana').length) return;
  UIkit.svg('#mapa-espana').svg.then(function (svg) {
    const base = $('#mapa-espana').data('base');
    $(svg)
      .find('.provincia')
      .on('click', function () {
        const province = $(this).data('rel');
        const url = base.replace('%s', province);
        location = url;
      });
  });
});
