/*
    Aplicación principal
 */

const domestiko = new Vue({
  data: {
    user: false,
    sectors: [],
    options: {
      notification: {
        timeout: 2000,
        pos: 'bottom-right',
        status: 'success',
      },
    },
  },
  created: function () {
    this.$on('user.loggedin', (user) => {
      //console.log('user.loggedin');
      $('body').addClass('loggedin');

      const url = new URL(location.href);
      if (url.searchParams.get('redirect')) {
        document.location = url.searchParams.get('redirect') + location.hash;
      } else if (
        url.pathname.match(/usuarios/) &&
        !url.pathname.match(/usuarios\/panel/)
      ) {
        document.location = '/usuarios/panel/';
      }
    });

    this.$on('user.loggedout', () => {
      //console.log('user.loggedout');
      $('body').removeClass('loggedin');
    });

    this.$on('form.login', () => {
      UIkit.offcanvas('#offcanvas-login').show();
    });
    this.$on('form.register', () => {
      UIkit.offcanvas('#offcanvas-register').show();
    });

    this.autoLogin();
    this.loadSectors();

    this.$nextTick(() => {
      if (!brownies.local.user) return;
      this.user = brownies.local.user;
      this.$emit('user.loggedin', this.user);
    });
  },
  methods: {
    login(user) {
      this.update(user);
      this.$emit('user.loggedin', user);
      // UIkit.notification('Bienvenido a Doméstiko', this.options.notification);
    },
    logout(params = {}, redirect = '/usuarios/salir/') {
      this.user = false;
      delete brownies.local.user;
      axios.get('/usuarios/salir/');
      this.$emit('user.loggedout');
      if (params) {
        redirect += urlParams(params);
      }
      if (!document.location.href.includes(redirect)) {
        document.location = redirect;
      }
    },
    update(user) {
      //console.log('domestiko.update', user);
      this.user = user;
      brownies.local.user = user;
    },
    messages(n = false) {
      this.user.messages = n;
      this.update(this.user);
    },
    notifications(n = false) {
      this.user.notifications = n;
      this.update(this.user);
    },
    autoLogin() {
      if ('_domestiko_user' in window) {
        this.login(window._domestiko_user);
      }
    },
    loadSectors() {
      if (
        brownies.local.sectors &&
        Array.isArray(brownies.local.sectors) &&
        brownies.local.sectors.length > 0 &&
        //tenemos sectores nuevos?
        brownies.local.sectors[0]._id === 'agricultura-y-ganaderia'
      ) {
        this.sectors = brownies.local.sectors;
        this.$emit('sectors.loaded');
        return;
      }
      axios
        .get('/terminos/arbol/sector/')
        .then((response) => response.data)
        .then((sectors) => {
          this.sectors = sectors;
          brownies.local.sectors = sectors;
          this.$emit('sectors.loaded');
        });
    },
  },
});
