const UserLogin = {
  data: function () {
    return {
      email: '',
      password: '',
      error: '',
      loading: false,
    };
  },
  computed: {
    loginDisabled: function () {
      return this.email == '' || this.password == '';
    },
  },
  methods: {
    login: function () {
      this.error = '';
      this.loading = true;

      axios
        .post('/usuarios/', {
          email: this.email,
          password: this.password,
        })
        .then((res) => {
          this.error = '';
          //console.log('Login OK!', res.data);
          domestiko.login(res.data.user);
          UIkit.offcanvas('#offcanvas-login').hide();
        })
        .catch((err) => {
          console.log('Login KO!', err.response);
          if (err.response) this.error = err.response.data.message;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  template: `
        <form class="user-login uk-form-stacked" :class="{loading: loading}">
            <div class="uk-margin-top">
                <label>Correo electrónico</label>
                <div class="uk-form-controls">
                    <input type="email" name="email" class="uk-input" autocomplete="email" v-model="email">
                </div>
            </div>
            <div class="uk-margin-top"> 
                <label>Contraseña</label>
              	<div class="uk-form-controls">
                    <input type="password" name="password" class="uk-input" autocomplete="current-password" v-model="password">
                </div>
            </div>
            <div class="uk-margin-top">
                <button class="uk-button uk-button-primary" @click.prevent="login()" :disabled="loginDisabled">
                    Iniciar sesión
                </button>
            </div>
            <div class="uk-margin-top">
                <a href="/usuarios/recuperar/">¿Has olvidado tu contraseña?</a>
            </div>
            <div class="uk-margin-top">
                <a href="/usuarios/alta/">Eres nuevo ¿quieres registrarte?</a>
            </div>
            <template v-if="error">
                <div class="uk-alert uk-alert-warning">
                    <span uk-icon="icon:warning"></span> {{ error }}
                </div>
            </template>
        </form>
    `,
};

(function () {
  ['block-login', 'offcanvas-login'].map((el) => {
    new Vue({
      el: document.getElementById(el),
      components: { 'user-login': UserLogin },
    });
  });
})();
