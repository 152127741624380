(function (el) {
  if (!el) return;

  new Vue({
    data: function () {
      return {
        message: '',
        file: false,
        loading: false,
        data: new FormData(),
      };
    },
    computed: {
      valid() {
        return !!this.message;
      },
    },
    created() {
      domestiko.$on('form.apply', this.open);
    },
    methods: {
      open(job) {
        if (!job) return;
        if (!domestiko.user) return;
        this.data.set('job', job);
        UIkit.modal('#jobs-apply-form').show();
      },
      async attach(e) {
        let file = e.target.files[0];
        if (!file) {
          return;
        }

        //comprobamos tipos de archivo
        if (!file.name.match(/^[^/]+.(jpg|png|gif|pdf)$/i)) {
          customAlert('Ha ocurrido un error', UIkitUploadI18n.invalidMime);
          return;
        }

        //redimensionamos
        if (file.type === 'image/jpeg' || file.type === 'image/x-png') {
          file = await resizeImage(file);
        }

        //comprobamos tamaño de archivo
        if (file.size > 2 * 1024 * 1024) {
          customAlert('Ha ocurrido un error', UIkitUploadI18n.invalidSize);
          return;
        }

        this.file = file;
        this.data.set('attachment', this.file, this.file.name);
      },
      publish() {
        this.data.set('message', this.message);

        axios
          .post('/empleo/inscripcion/', this.data)
          .then((response) => response.data)
          .then((response) => {
            this.loading = false;
            domestiko.$emit('job.applied');
            UIkit.modal('#jobs-apply-form').hide();
            customAlert(
              '¡Te has inscrito correctamente!',
              'Hemos enviado tu solicitud a la persona responsable del empleo.',
            );
          })
          .catch((error) => {
            console.error(error.response.data);
            UIkit.modal.alert(
              '<div class="uk-alert uk-alert-danger">' +
                'Ha ocurrido un error con su inscripción: ' +
                error.response.data.message +
                '</div>',
            );
          });
      },
    },
    template: `
      <div id="jobs-apply-form" uk-modal>
      <div class="uk-modal-dialog uk-margin-auto-vertical">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <div class="uk-modal-body">
          <div class="uk-margin-bottom">
            <h3>Mensaje para el empleador</h3>
            <p>
              Recuerda que cuanto mejor describas tu perfil profesional y más detallada sea tu candidatura, más opciones tendrás para ser seleccionado para la oferta de empleo.
            </p>
            <textarea class="uk-textarea" rows="5" v-model="message"></textarea>
          </div>
          <div>
            <div uk-form-custom class="uk-width-1-1"> 
              <input type="file" @change="attach" accept="image/*, application/pdf">
              <div class="uk-dropzone">
                <span class="icon icon-attach"></span> 
                <span v-if="file">{{file.name}}</span>
                <span v-else>
                  Selecciona un archivo<br/> 
                  <small>(únicamente archivos de imagen o PDF)</small>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="uk-modal-footer uk-text-right">
          <button
              :disabled="loading || !valid"
              @click.prevent="publish()"
              class="uk-button uk-button-dmtk"
              :class="{'uk-button-secondary': !valid}"
          >{{ loading ? 'Enviando... ': 'Apuntarse a la oferta' }}</button>
        </div>
      </div>
      </div>
	  `,
  }).$mount(el);
})(document.getElementById('jobs-apply-form'));
