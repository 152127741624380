var UserRecover = {
  data: function () {
    return {
      email: '',
      loading: false,
      status: 'form',
    };
  },

  computed: {
    formDisabled: function () {
      return (
        this.email === '' ||
        this.email.indexOf('@') < 0 ||
        this.email.indexOf('.') < 0
      );
    },
  },

  methods: {
    recover: function () {
      this.status = 'form';
      this.loading = true;

      axios
        .post('/usuarios/recuperar/', { email: this.email })
        .then((res) => res.data)
        .then((res) => {
          //console.log('Recover OK!');
          domestiko.$emit('user.recover', res.result);
        })
        .catch((e) => {
          console.log('Recover KO!', e.message);
        })
        .finally(() => {
          this.loading = false;
          this.status = 'success';
          setTimeout(() => {
            this.status = 'form';
          }, 20000);
        });
    },
  },

  template: `
        <form class="uk-form-stacked user-register" :class="{loading: loading}">
            <div class="uk-margin-top">
                <label>Correo electrónico</label>
              	<input type="email" name="email" class="uk-input" v-model="email">
            </div>
            <div class="uk-margin-top">
                <button class="uk-button uk-button-primary" @click.prevent="recover()" :disabled="formDisabled">
                    Recuperar contraseña
                </button>
            </div>
            <div class="uk-alert uk-alert-success" v-if="status == 'success'">
              Hemos recibido tu petición, si tienes cuenta en Doméstiko
              recibirás un correo para recuperar su contraseña.
            </div>
            <div class="uk-margin-top">
                <a href="/usuarios/alta/">Eres nuevo ¿quieres registrarte?</a>
            </div>
        </form>
    `,
};

(function (el) {
  if (!el) return;
  new Vue({
    el: el,
    components: { 'user-recover': UserRecover },
  });
})(document.getElementById('block-recover'));
